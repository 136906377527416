import {
    Box,
    Center,
    Image,
    Text
} from '@chakra-ui/react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import React, { useEffect, useState } from 'react';
import {
    bigTitleSize,
    marginsLeft,
    marginsRight,
    subTitleSize,
    titleFont,
} from '../CustomTheme';
import { FetchData, root } from '../services/cmsAPI';
import Carousel from "../Components/Carousel.tsx";


export const PageTitle = (props) => <Box textAlign="center" display='flex' justifyContent='center' flexWrap='wrap'>
                            <Text color='white' fontSize={bigTitleSize} fontFamily={titleFont} as = 'b'>
                                {props.text}
                            </Text>
                        </Box>

export const SubTitle = (props) => <Center>
                            <Box textAlign='center' display="inline-block" bg='primary.900' rounded='md' >
                                <Text m={4} color='white' fontSize={subTitleSize} fontFamily = {titleFont}>
                                    {props.text.toUpperCase()}
                                </Text>
                            </Box>
                            </Center>
                            

export const BlockMargin = ()=><Box minH='4dvh'></Box>

export const RTFrenderer = (props) => (
                <BlocksRenderer blocks = {{
                                paragraph: ({ children }) => { return children[0].props.text === "" ? (
                                        <p className="">ㅤ</p>
                                        ) : (
                                        <p className="">{children}</p>
                                    );
                                    },}} content = {props.content}/>
)

export function Page(props){
        const [blocks, setBlocks] = useState([]);
        const [title, setTitle] = useState("")
        const [data, setData] = useState(null);
        const [loading, setLoading] = useState(true);
        
        useEffect(() =>{
            setLoading(true);
            FetchData("/api/pages/".concat(props.pageData.id.toString()).concat("?populate[Blocks][populate]=*&_sort=order:ASC"), setData, setLoading);
        },[props.pageData.id])

        useEffect(() => {
            //We need the page to re-render when the routing changes, that's why we need to fetch inside use-effect and can't use custom hook GetData
            if(!loading)
                {
                let blocks = [];
                let title = null;
                const attributes = data.attributes;
                title = attributes.PageTitle
                console.log(title)
                for (var k = 0; attributes.Blocks[k]; k++){
                    if(attributes.Blocks[k].__component==="text.sub-title")
                    {
                        blocks.push(<SubTitle text={attributes.Blocks[k].SubTitle}/>)
                        blocks.push(<BlockMargin/>)
                    }
                    if(attributes.Blocks[k].__component==="text.text-block" && attributes.Blocks[k].Text!=null )
                    {
                        //blocks.push(<TextBlock text={attributes.Blocks[k].Text}/>)
                        blocks.push(<Box maxW = '90%' maxH='90%' marginLeft='5%' marginRight='5%'>
                            <RTFrenderer content = {attributes.Blocks[k].Text}/>
                            </Box>)
                        blocks.push(<BlockMargin/>)
                    }
                    if(attributes.Blocks[k].__component==="media.image" && attributes.Blocks[k].Media.data[0]!=null)
                          {
                            for (var l = 0; attributes.Blocks[k].Media.data[l]; l++){
                                let url = '';
                                if(attributes.Blocks[k].Media.data[0].attributes.formats.hasOwnProperty('large'))
                                    {
                                        url = root.concat(attributes.Blocks[k].Media.data[0].attributes.formats.large.url)
                                    }
                                else if(attributes.Blocks[k].Media.data[0].attributes.formats.hasOwnProperty('medium'))
                                    {
                                        url = root.concat(attributes.Blocks[k].Media.data[0].attributes.formats.medium.url)
                                }
                                else{
                                    url = root.concat(attributes.Blocks[k].Media.data[0].attributes.formats.small.url)
                                }
                                blocks.push(
                                    <Center>
                                        <Image 
                                        maxH='65dvh'
                                        maxW='65dvw'
                                        marginLeft='5%' 
                                        marginRight='5%'
                                        src= {url}
                                        alt='Afbeelding' 
                                        />
                                    </Center>
                                )
                                blocks.push(<BlockMargin/>);
                            }
                        }
                    if(attributes.Blocks[k].__component==="media.iframe" && attributes.Blocks[k].URL!=null)
                        {
                            blocks.push(
                                <>
                                <Center>
                                    <iframe
                                        src={attributes.Blocks[k].URL}
                                        style={{marginLeft: '2dvw', marginRight: '2dvw', width: '100%', height: '100dvh' }}
                                        frameborder="1"
                                        >
                                    </iframe>
                                </Center>
                                <BlockMargin/>
                                </>
                            )
                        }
                    if(attributes.Blocks[k].__component==="media.image-carousel")
                        {
                            let URLs = [];
                            for (var l = 0; attributes.Blocks[k].Images.data[l]; l++){
                                URLs.push(root.concat(attributes.Blocks[k].Images.data[l].attributes.formats.medium.url))
                            }
                            console.log(URLs)
                            blocks.push(
                                <>
                                <Carousel images={URLs}/>
                                <BlockMargin/>
                                </>
                            )
                        
                        }


                }
                setBlocks(blocks);
                setTitle(title);
                }
        },[loading])
        
        //Note:  in the follow render function blocks is mapped, since react requires assigning keys for efficient rendering of an array.
        return( 
            loading?
            <>
            </>
            :
            <>
                <PageTitle text={title.toUpperCase()}/>  
                <Box marginLeft={marginsLeft} marginRight={marginsRight} bg='yellow.200' rounded='md' boxShadow = 'dark-lg' minH = '80dvh' overflow='hidden'>
                        <BlockMargin/>
                        <>{blocks.map((e,i) => <>{e}</>)}</> 
                </Box>
                <BlockMargin/>
            </>
        )
}

