import {extendTheme,} from '@chakra-ui/react'
import { createIcon } from '@chakra-ui/icons'
import '@fontsource/lato'
import '@fontsource/noto-sans-jp';


export const titleFont = "Lato"
export const textFont = "Noto Sans JP"

export const marginsLeft = [5,50, 100,150,200,250];
export const marginsRight = [5,50, 100,150,200,250];
export const bigTitleSize = [30,35,45,50,55,60];
export const subTitleSize = [15,20,25,30,35,40];
export const normalTextSize = [15,20,22,25,28,33];
export const smallTextSize = [9,11,12,14,16,18];



export const customTheme = extendTheme({
    colors: {
            "black": "#100f0f",
            "menu-color": "#29241A",
            "gray": {
                "50": "#fafaf9",
                "100": "#f1f1f1",
                "200": "#e8e7e7",
                "300": "#d4d4d3",
                "400": "#adacac",
                "500": "#807f7d",
                "600": "#565453",
                "700": "#383634",
                "800": "#21201e",
                "900": "#1b1918"
            },
            "orange": {
                "50": "#fbfafa",
                "100": "#efedea",
                "200": "#ded8d2",
                "300": "#c5bab0",
                "400": "#afa092",
                "500": "#9b8877",
                "600": "#88725d",
                "700": "#735841",
                "800": "#5b4532",
                "900": "#4b3929"
            },
            "yellow": {
                "50": "#fefefe",
                "100": "#f9f9f7",
                "200": "#eeede8",
                "300": "#e1dfd6",
                "400": "#cfccbe",
                "500": "#aea890",
                "600": "#8e8666",
                "700": "#726840",
                "800": "#564e2f",
                "900": "#474027"
            },
            "green": {
                "50": "#fafbfb",
                "100": "#e5ece9",
                "200": "#cbd9d1",
                "300": "#aec3b8",
                "400": "#90ad9e",
                "500": "#729783",
                "600": "#528067",
                "700": "#37654d",
                "800": "#2d523f",
                "900": "#254433"
            },
            "teal": {
                "50": "#f8fafa",
                "100": "#e2eae9",
                "200": "#c9d8d7",
                "300": "#acc2c1",
                "400": "#88a7a6",
                "500": "#68908f",
                "600": "#477775",
                "700": "#335d5b",
                "800": "#2b4e4c",
                "900": "#23403f"
            },
            "cyan": {
                "50": "#f9fafa",
                "100": "#e4eaeb",
                "200": "#d8e1e2",
                "300": "#cbd7d9",
                "400": "#a4b8bc",
                "500": "#91aaae",
                "600": "#7d9b9f",
                "700": "#5d8287",
                "800": "#416c73",
                "900": "#315459"
            },
            "blue": {
                "50": "#f5f6f7",
                "100": "#d9dee3",
                "200": "#bec7cf",
                "300": "#a0adb9",
                "400": "#8596a5",
                "500": "#6d8193",
                "600": "#556d82",
                "700": "#3a536a",
                "800": "#2f4457",
                "900": "#273746"
            },
            "purple": {
                "50": "#f7f7f9",
                "100": "#e0dde6",
                "200": "#c9c5d4",
                "300": "#a9a2ba",
                "400": "#938aa9",
                "500": "#776c93",
                "600": "#655884",
                "700": "#544576",
                "800": "#453865",
                "900": "#33294b"
            },
            "pink": {
                "50": "#f9f7f8",
                "100": "#e7dfe3",
                "200": "#d4c5cd",
                "300": "#bca4b0",
                "400": "#ab8c9c",
                "500": "#957084",
                "600": "#875c73",
                "700": "#774660",
                "800": "#5f344b",
                "900": "#462637"
            },
            "primary": {
                "50": "#f8f7f6",
                "100": "#e4dfdb",
                "200": "#cdc4bc",
                "300": "#b2a396",
                "400": "#a39182",
                "500": "#8e7966",
                "600": "#7d654f",
                "700": "#695039",
                "800": "#584330",
                "900": "#403023"
            }        
    }

})

export const HomeIcon = createIcon({
    displayName: 'HomeIcon',
    d: 'M 12 2 A 1 1 0 0 0 11.289062 2.296875 L 1.203125 11.097656 A 0.5 0.5 0 0 0 1 11.5 A 0.5 0.5 0 0 0 1.5 12 L 4 12 L 4 20 C 4 20.552 4.448 21 5 21 L 9 21 C 9.552 21 10 20.552 10 20 L 10 14 L 14 14 L 14 20 C 14 20.552 14.448 21 15 21 L 19 21 C 19.552 21 20 20.552 20 20 L 20 12 L 22.5 12 A 0.5 0.5 0 0 0 23 11.5 A 0.5 0.5 0 0 0 22.796875 11.097656 L 12.716797 2.3027344 A 1 1 0 0 0 12.710938 2.296875 A 1 1 0 0 0 12 2 z'
})

