import {
    Box,
    Image,
    HStack,
    Text,
    Center,
    Grid,
    Link,
    useClipboard,
    useBreakpointValue
} from '@chakra-ui/react';
import {
    normalTextSize,
    subTitleSize,
    smallTextSize,
} from '../CustomTheme';
import { GetData, root, } from '../services/cmsAPI';
import {PageTitle,SubTitle, BlockMargin} from './page.js'
import { useEffect, useState } from 'react';
import { BiNoEntry } from 'react-icons/bi';

const LeiderButton = (props) => { 
    const [isHovered, setIsHovered] = useState(false)
    const { onCopy, value, setValue, hasCopied } = useClipboard('')
    const [buttonText, setButtonText] = useState([]);
    
    useEffect(() =>{
        setValue(props.telefoon);
        setButtonText(props.voornaam.concat(' ').concat(props.naam).concat('\n').concat(props.telefoon))
    },[props.telefoon])

    useEffect(() =>{
        if(hasCopied)
        {
            setButtonText("Telefoonnr. gekopieerd")
            setTimeout(() => {setButtonText(props.voornaam.concat(' ').concat(props.naam).concat('\n').concat(props.telefoon))},2500)
        }
    },[hasCopied])

    return(
        <Box position="relative"  maxW = {{base: "45dvw", md: "20dvw"}} maxH = {{base: "30vh", md: "40vh"}} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
        borderRadius = 'xl' overflow='hidden' display='flex' alignItems='center'
        justifyContent='center' onDoubleClick={props.isDesktop ? null : onCopy} onClick={props.isDesktop ? onCopy : null}>
        <Image src={props.afbeelding} alt="Your alt text"/>
            {isHovered ?
                <Text
                position="absolute"
                top='0'
                bottom='0'
                left='0'
                right='0'
                color="white"
                bg="rgba(0, 0, 0, 0.5)" // semi-transparent black
                display='flex'
                alignItems='center'
                justifyContent='center'
                textAlign = 'center'
                p={2}
                whiteSpace='pre-line'>
                {buttonText}
                </Text>
                :
                <></>
            }
        </Box>
    )
}

export function Contact(props){
    const{data,loading} = GetData("/api/leiders?sort[0]=publishedAt:desc&populate=*");
    let kapoenen = [];
    let welpen = [];
    let jongverkenners = [];
    let verkenners = [];
    let groepsleiding = [];
    const isDesktop = useBreakpointValue({ base: false, md: true });


    if(!loading)
        {
            for (var k = 0; data[k]; k++){
                const element = <LeiderButton voornaam = {data[k].attributes.Voornaam} naam = {data[k].attributes.Achternaam} 
                telefoon = {data[k].attributes.Telefoonnummer} 
                afbeelding = {root.concat(data[k].attributes.Profielfoto.data.attributes.formats.small.url)}
                isDesktop = {isDesktop}/>

                if(data[k].attributes.Tak == 'Kapoenen'){
                    kapoenen.push(element);
                }
                else if(data[k].attributes.Tak == 'Welpen'){
                    welpen.push(element);
                }
                else if(data[k].attributes.Tak == 'Jongverkenners')
                    {
                        jongverkenners.push(element);
                    }
                else if(data[k].attributes.Tak == 'Verkenners')
                    {
                        verkenners.push(element);
                    }
                else if(data[k].attributes.Tak == 'Groepsleiding')
                {
                        groepsleiding.push(element);
                }
            }
        }

    return(
        loading ?
        <></>
        :
        <>
            <PageTitle text="CONTACT"/>
            <Box marginLeft='2dvw' marginRight='2dvw'  overflow='hidden'>
                <Box textAlign="center" display='flex' justifyContent='center' >
                <Text color='white' fontSize={smallTextSize}>
                {
                    !isDesktop ?
                    "Hoover over de foto's voor onze namen en contactgegevens. Dubbelklik om een telefoonnummer te kopiëren."
                    :
                    "Hoover over de foto's voor onze namen en contactgegevens. Klik om een telefoonnummer te kopiëren."
                }
                </Text>
                </Box>  
                <BlockMargin/>
                <SubTitle text='Adres'/>
                <Box textAlign="center" display='flex' justifyContent='center' >
                    <Text color='white' fontSize={normalTextSize}>Heistraat 206b 9100 Sint-Niklaas (achter de Christus Koningkerk)</Text>
                </Box>
                <BlockMargin/>
                <SubTitle text='Groepsleiding'/>
                <Box textAlign="center" display='flex' justifyContent='center' >
                    <Link href="mailto:groepsleiding@sint-kristoffel.be" color="white">
                        <Text color='white' fontSize={normalTextSize}>groepsleiding@sint-kristoffel.be</Text>
                    </Link>
                </Box>
                <BlockMargin/>
                <Center>
                    <Grid templateColumns={{base: "repeat(2,1fr)", md: "repeat(3, 1fr)"}} gap={4} justifyItems="center" alignItems="center">
                        {groepsleiding.map((e,i) => <>{e}</>)}
                    </Grid>
                </Center>
                <BlockMargin/>
                <SubTitle text='Kapoenen'/>
                <Box textAlign="center" display='flex' justifyContent='center' >
                    <Link href="mailto:kapoenen@sint-kristoffel.be" color="white">
                        <Text color='white' fontSize={normalTextSize}>kapoenen@sint-kristoffel.be</Text>
                    </Link>
                </Box>
                <BlockMargin/>
                <Center>
                <Grid templateColumns={{base: "repeat(2,1fr)", md: "repeat(4, 1fr)"}} gap={4}>
                    {kapoenen.map((e,i) => <>{e}</>)}
                </Grid>
                </Center>
                <BlockMargin/>
                <SubTitle text='Welpen'/>
                <Box textAlign="center" display='flex' justifyContent='center' >
                    <Link href="mailto:welpen@sint-kristoffel.be" color="white">
                        <Text color='white' fontSize={normalTextSize}>welpen@sint-kristoffel.be</Text>
                    </Link>
                </Box>
                <BlockMargin/>
                <Center>
                <Grid templateColumns={{base: "repeat(2,1fr)", md: "repeat(4, 1fr)"}} gap={4}>
                    {welpen.map((e,i) => <>{e}</>)}
                </Grid>
                </Center>
                <BlockMargin/>
                <SubTitle text='Jongverkenners'/>
                <Box textAlign="center" display='flex' justifyContent='center' >
                    <Link href="mailto:jongverkenners@sint-kristoffel.be" color="white">
                        <Text color='white' fontSize={normalTextSize}>jongverkenners@sint-kristoffel.be</Text>
                    </Link>
                </Box>
                <BlockMargin/>
                <Center>
                <Grid templateColumns={{base: "repeat(2,1fr)", md: "repeat(4, 1fr)"}} gap={4}>
                    {jongverkenners.map((e,i) => <>{e}</>)}
                </Grid>
                </Center>
                <BlockMargin/>
                <SubTitle text='Verkenners'/>
                <Box textAlign="center" display='flex' justifyContent='center' >
                    <Link href="mailto:verkenners@sint-kristoffel.be" color="white">
                        <Text color='white' fontSize={normalTextSize}>verkenners@sint-kristoffel.be</Text>
                    </Link>
                </Box>
                <BlockMargin/>
                <Center>
                <Grid templateColumns={{base: "repeat(2,1fr)", md: "repeat(4, 1fr)"}} gap={4}>
                    {verkenners.map((e,i) => <>{e}</>)}
                </Grid>
                </Center>
                <BlockMargin/>
            </Box>
        </>
    )
}